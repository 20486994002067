import React from "react"
import { Container, Accordion } from "react-bootstrap"
import styled from "styled-components"
import { FaqSection, FaqH2, AccordionDiv } from "./AffiliateStyle"
import FaqTemplate from "./FaqTemplate"

function AffiliateFaq({ propsData }) {
  const {
    Title,
    Article1,
    Article2,
    Article3,
    Article4,
    Detail1,
    Detail2,
    DetailEmail,
  } = propsData.Faq
  return (
    <>
      <FaqSection>
        <Container>
          <FaqH2>{Title}</FaqH2>
          <MainDiv>
            {/* GENERAL */}

            <AccordionDiv title={Article1.Title}>
              <Accordion>
                <FaqTemplate Key="0" Title={Article1.Question1}>
                  <p>{Article1.Answer1}</p>
                  <p>{Article1.Answer1a}</p>
                </FaqTemplate>
                <FaqTemplate Key="1" Title={Article1.Question2}>
                  <p>{Article1.Answer2}</p>
                </FaqTemplate>
                <FaqTemplate Key="2" Title={Article1.Question3}>
                  <p>{Article1.Answer3}</p>
                </FaqTemplate>
                <FaqTemplate Key="3" Title={Article1.Question4}>
                  <p>{Article1.Answer4}</p>
                </FaqTemplate>
                <FaqTemplate Key="4" Title={Article1.Question5}>
                  <p>{Article1.Answer5}</p>
                </FaqTemplate>
              </Accordion>
            </AccordionDiv>

            {/* REWARDS */}

            <AccordionDiv title={Article2.Title}>
              <Accordion>
                <FaqTemplate Key="0" Title={Article2.Question1}>
                  <p>{Article2.Answer1}</p>
                </FaqTemplate>
                <FaqTemplate Key="1" Title={Article2.Question2}>
                  <p>{Article2.Answer2}</p>
                </FaqTemplate>
                <FaqTemplate Key="2" Title={Article2.Question3}>
                  <p>{Article2.Answer3}</p>
                </FaqTemplate>
              </Accordion>
            </AccordionDiv>

            {/* PAYMENTS */}

            <AccordionDiv title={Article3.Title}>
              <Accordion>
                <FaqTemplate Key="0" Title={Article3.Question1}>
                  <p>{Article3.Answer1}</p>
                </FaqTemplate>
                <FaqTemplate Key="1" Title={Article3.Question2}>
                  <p>{Article3.Answer2}</p>
                </FaqTemplate>
                <FaqTemplate Key="2" Title={Article3.Question3}>
                  <p>{Article3.Answer3}</p>
                </FaqTemplate>
              </Accordion>
            </AccordionDiv>

            {/* MARKETING */}

            <AccordionDiv title={Article4.Title}>
              <Accordion>
                <FaqTemplate Key="0" Title={Article4.Question1}>
                  <p>{Article4.Answer1}</p>
                </FaqTemplate>
                <FaqTemplate Key="1" Title={Article4.Question2}>
                  <p>{Article4.Answer2}</p>
                </FaqTemplate>
              </Accordion>
            </AccordionDiv>

            <DetailsText>
              {Detail1}
              <DetailsSpan href="mailto:affiliate@transfez.com">
                {DetailEmail}
              </DetailsSpan>
              {Detail2}
            </DetailsText>
          </MainDiv>
        </Container>
      </FaqSection>
    </>
  )
}

export default AffiliateFaq

const MainDiv = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(15, 44, 87, 0.12);
  border-radius: 15px;
  padding: 30px 50px;
`

const DetailsText = styled.p`
  font-family: "Typefez-med";
  color: #333334;
  font-size: 16px;
  margin-top: 50px;
`

const DetailsSpan = styled.a`
  color: #da649f;
`
