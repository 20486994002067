import React, { useState } from "react"
import { Accordion, Card, Button } from "react-bootstrap"
import styled from "styled-components"
import { TitleH4 } from "./AffiliateStyle"

function FaqTemplate({ Title, Key, children }) {
  // const [activeIndex, setActiveIndex] = useState(0)

  return (
    <>
      <Card style={{ border: "none" }}>
        <Card.Header
          style={{
            backgroundColor: "white",
            borderBottom: "none",
            padding: "0",
          }}
        >
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={Key}
            className="p-0 shadow-none text-left"
            // onClick={handleClick}
          >
            <TitleH4 logo="+">{Title}</TitleH4>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={Key}>
          <Card.Body style={{ padding: '0px 0px 0px 50px'}}>
            <CardBodyText>{children}</CardBodyText>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  )
}

export default FaqTemplate

const CardBodyText = styled.p`
  font-family: "Typefez-reg";
  font-size: 16px;
`