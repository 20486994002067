import React, { useState } from "react"
import { Link } from "gatsby"
import { Row, Col, Container } from "react-bootstrap"
import billImg from "../../images/affiliate-bill-image.png"
import { ActionButton, ActionH2 } from "./AffiliateStyle"
import { DotAccordion } from "../HomeComponents/DotAccordion"
import styled from "styled-components"

function AffiliateHiw({ propsData }) {
  const {
    Title,
    Point1,
    Point2,
    Point3,
    Desc1,
    Desc2,
    Desc3,
    Button,
  } = propsData.Hiw

  const [activeId, setActiveId] = useState("0")

  const DataList = [
    {
      eventId: "0",
      name: `1. ${Point1}`,
      content: Desc1,
    },
    {
      eventId: "1",
      name: `2. ${Point2}`,
      content: Desc2,
    },
    {
      eventId: "2",
      name: `3. ${Point3}`,
      content: Desc3,
    },
  ]

  return (
    <CustomContainer>
      <ActionH2>{Title}</ActionH2>
      <Row>
        <Col md={8}>
          <MainDiv pad="40px 30px 30px 30px">
            <DotAccordion
              data={DataList}
              isHowToUse
              sendToParent={e => setActiveId(e)}
            />
            <Link to="#tnc">
              <ActionButton mtop="20px">{Button}</ActionButton>
            </Link>
          </MainDiv>
        </Col>
        <Col md={4} style={{ position: "relative" }}>
          <CustomImg src={billImg} alt="how to use" />
        </Col>
      </Row>
    </CustomContainer>
  )
}

export default AffiliateHiw

const CustomContainer = styled(Container)`
  margin-top: 100px;
  margin-bottom: 150px;
`

const MainDiv = styled.div`
  box-shadow: 1px 1px 15px rgba(73, 171, 184, 0.2);
  -webkit-box-shadow: 1px 1px 15px rgba(73, 171, 184, 0.2);
  -moz-box-shadow: 1px 1px 15px rgba(73, 171, 184, 0.2);
  border-radius: 15px;
  padding: ${p => p.pad};
  margin: 30px 0px 10px 0px;
  @media (max-width: 576px) {
    width: 90%;
  }
`

const CustomImg = styled.img`
  position: absolute;
  left: -40px;
  bottom: -10px;
  width: 90%;
  @media (max-width: 768px) {
    display: none;
  }
`
