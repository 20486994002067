import styled from "styled-components"

import { Row, Col, Button, Form } from "react-bootstrap"

export const HiwSection = styled.section`
  position: relative;
  padding-top: 3rem;
  padding-bottom: 3rem;
`

export const HiwH2 = styled.h2`
  font-family: "GothamMedium";
  color: #17a2b8;
  margin-bottom: 3rem;
  text-align: center;
  @media (min-width: 768px) {
    text-align: left;
  }
`

export const HiwCol = styled(Col)`
  text-align: center;
  @media (min-width: 768px) {
    text-align: left;
  }
`

export const HiwUl = styled.ul`
  counter-reset: counter;
  text-align: left;
  padding-left: 0;
  list-style: none;
`

export const HiwLi = styled.li`
  counter-increment: section;
  position: relative;
  margin-left: 15px;
  padding-left: 25px;
  border-left: ${props => props.bl};
  padding-bottom: 1.5rem;
  ::after {
    content: counter(section);
    color: white;
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-family: "GothamMedium";
    background-color: #ffd27c;
    position: absolute;
    top: 0;
    left: -15px;
  }
`

export const HiwH4 = styled.h3`
  color: #17a2b8;
  font-size: 1.5rem;
`

export const HiwP = styled.p`
  color: #9f9698;
  margin-bottom: 0;
`
export const HiwButton = styled(Button)`
  font-size: 0.875rem;
  font-family: "GothamMedium";
  background-color: #196e97;
  border-color: #196e97;
  color: #ffd27c;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 10px;
  :hover {
    background-color: #196e97;
    border-color: #196e97;
    color: #ffc107;
  }
  :active {
    background-color: #196e97;
    border-color: #196e97;
    color: #ffc107;
  }
  :focus {
    background-color: #196e97;
    border-color: #196e97;
    color: #ffc107;
  }
  @media (max-width: 414px) {
    margin-right: 0px;
  }
`

export const ToolsSection = styled.section`
  min-height: 100vh;
  position: relative;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: #f2f2f2;
  background-image: url(${props => props.background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom right;
  @media (max-width: 767px) {
    padding-bottom: 100px;
    background-image: url(${props => props.bgres});
    background-position: bottom center;
    background-size: 100% auto;
  }
`
export const ToolsCol = styled(Col)`
  position: relative;
  padding-top: 100px;
`

export const ToolsH2 = styled.h2`
  font-family: "GothamMedium";
  color: white;
  display: block;
  position: absolute;
  background-color: #48a9b5;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 50px;
  top: -60px;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  @media (min-width: 992px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @media (max-width: 991px) {
    right: -200px;
    left: 0;
  }
  @media (max-width: 767px) {
    font-size: 18px;
    right: 1rem;
    left: 1rem;
  }
`
export const ToolsDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffd27c;
  display: inline-block;
  margin-left: 0.25rem;
`

export const ToolsH4 = styled.h3`
  font-family: "GothamBook";
  color: #196e97;
  font-size: 1.5rem;
`

export const ToolsP = styled.p`
  margin-bottom: 1rem;
  color: #6c6365;
`

export const ActionSection = styled.section`
  padding-bottom: 3rem;
  padding-top: 3rem;
`

export const ActionWrapper = styled.div`
  text-align: center;
`

export const ActionH2 = styled.h2`
  font-size: 50px;
  font-family: "Typefez-extrabold";
  color: #102c57;
  @media (max-width: 576px) {
    font-size: 38px;
  }
`
export const ActionBr = styled.br`
  display: none;
  @media (min-width: 768px) {
    display: inline;
  }
`

export const ActionP = styled.p`
  font-family: "Typefez-med";
  font-size: 16px;
  color: #333334;
  margin-top: 2rem;
  margin-bottom: 2rem;
`

export const ActionButton = styled(Button)`
  min-width: ${p => p.minWidth || "200px"};
  height: 40px;
  font-family: "Typefez-med";
  border-radius: 10px;
  font-size: 16px;
  background-color: #db64a0;
  border: none;
  color: #fff;
  margin-top: ${p => p.mtop};
  margin-bottom: ${p => p.mbottom};
  box-shadow: none;
  :hover,
  :active,
  :focus {
    filter: brightness(93%);
    background-color: #db64a0 !important;
    border: none !important;
    color: #fff !important;
    outline: none !important;
    box-shadow: none !important;
  }
  @media (max-width: 576px) {
    font-size: ${p => p.fsize576};
  }
`

export const AffiliateHr = styled.hr`
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  color: #6c6365;
`

export const FaqSection = styled.section`
  padding-bottom: 150px;
  padding-top: 100px;
`

export const FaqH2 = styled.h2`
  font-family: "Typefez-extrabold";
  color: #102c57;
  text-align: center;
  font-size: 50px;
  margin-bottom: 3rem;
`

export const AccordionDiv = styled.div`
  position: relative;
  margin-top: 60px;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  /* border-top: 1px solid #dee2e6; */
  ::before {
    content: attr(title);
    font-size: 24px;
    font-family: "Typefez-extrabold";
    display: block;
    color: #2ebcd0;
    position: absolute;
    top: -30px;
  }
`

export const TitleH4 = styled.a`
  font-family: "Typefez-med";
  font-size: 1.5rem;
  color: #da649f;
  display: block;
  padding-left: 50px;
  padding-bottom: 0;
  padding-top: 0;
  margin-bottom: 1rem;
  ::before {
    content: "${props => props.logo}";
    color: #fff;
    font-family: "Typefez-med";
    background-color: #da649f;
    width: 20px;
    padding-bottom: 2px;
    margin-top: 8px;
    line-height: 18px;
    text-align: center;
    border-radius: 5px;
    display: block;
    position: absolute;
    left: 0;
  }
  :hover {
    color: #da649f;
  }
`

export const ScrollingBox = styled.div`
  overflow-y: scroll;
  scroll-behavior: smooth;
`

export const TncSection = styled.div`
  padding: 50px 100px 200px 100px;
  border-radius: 15px 15px 0px 0px;
  background-color: #2ebcd0;
  display: block;
  @media (max-width: 576px) {
    padding: 50px 30px 100px 30px;
  }
`

export const TncH2 = styled.h2`
  font-family: "Typefez-black";
  font-size: 50px;
  color: #fff;
  text-align: center;
  margin-bottom: 3rem;
  @media (max-width: 576px) {
    font-size: 38px;
  }
`

export const TncH4 = styled.h4`
  font-family: "GothamMedium";
  color: #48a9b5;
  text-align: center;
`

export const Wrapper = styled.div`
  text-align: justify;
  background-color: #fff;
  /* max-height: 500px; */
  position: relative;
  /* overflow: auto; */
  padding: 1.5rem;
  border-radius: 12px;
  @media (min-width: 768px) {
    padding: 3rem;
  }
`

export const iconA = styled.a`
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  width: 50px;
`

export const TncButton = styled(Button)`
  font-size: 18px;
  width: 100%;
  max-width: 400px;
  font-family: "Typefez-med";
  background-color: #db64a0;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 10px 0px;
  &:hover {
    filter: brightness(95%);
    background-color: #db64a0;
    color: #fff;
  }
  &:active {
    filter: brightness(95%);
    background-color: #db64a0;
    color: #fff;
  }
  &:focus {
    background-color: #db64a0;
    color: #fff;
    filter: brightness(95%);
  }
`

export const TncBr = styled.br`
  @media (min-width: 768px) {
    display: none;
  }
`

export const FormDiv = styled.div`
  text-align: center;
  margin-top: 3rem;
`

export const Ornament = styled.img`
  max-width: 380px;
  position: absolute;
  right: 0;
  bottom: -150px;
  @media (max-width: 768px) {
    max-width: 250px;
    left: 50%;
    transform: translateX(-50%);
  }
`

export const FormRow = styled(Row)`
  margin-bottom: 1rem;
`

export const FormLabel = styled.label`
  font-family: "Typefez-reg";
  color: #333334;
  position: relative;
  text-align: left;
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
  :after {
    content: ":";
    position: absolute;
    right: 10px;
  }

  @media (max-width: 768px) {
    white-space: nowrap;
    ::after {
      content: ""
    }
  }
`

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 2rem;
`

export const DisclaimerDiv = styled.div`
  > sup {
    font-family: "Typefez-reg";
    color: #333334;
  }
  > small {
    font-family: "Typefez-reg";
    color: #333334;
  }
`

export const ButtonSend = styled(Button)`
  font-family: "Typefez-med";
  font-weight: 600;
  background-color: #db64a0;
  border-color: #db64a0;
  color: #fff;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  margin-left: auto !important;
  @media (min-width: 768px) {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  :hover {
    color: #fff;
    background-color: #db64a0;
    filter: brightness(90%);
    border: none !important;
  }
`

export const FlexFill = styled.div`
  flex: 1 1 auto;
  line-height: 15px;
  height: 2.4rem;

  > small {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.05em;
    color: #333334;
    font-family: "Typefez-reg";
  }
`

export const FormControl = styled(Form.Control)`
  font-family: "Typefez-reg";
  color: #333334;
  width: 100%;
  outline: none !important;
  box-shadow: none !important;
  :focus,
  :hover,
  :active {
    outline: none !important;
    box-shadow: none !important;
  }
  ::placeholder {
    opacity: 0.5;
  }
`
