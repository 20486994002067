import React from "react"
import { Link } from "gatsby"

import { Container } from "react-bootstrap"
import {
  ActionSection,
  ActionWrapper,
  ActionBr,
  ActionH2,
  ActionP,
  ActionButton,
} from "./AffiliateStyle"

function AffiliateAction({ propsData }) {
  const { Title1, Title2, Desc1, Desc2, Button } = propsData.Action
  return (
    <>
      <ActionSection>
        <Container>
          <ActionWrapper>
            <ActionH2>
              {Title1}
              <ActionBr />
              {Title2}
            </ActionH2>
            <ActionP>
              {Desc1}
              <br />
              {Desc2}
            </ActionP>
            <Link to="#tnc">
              <ActionButton>{Button}</ActionButton>
            </Link>
          </ActionWrapper>
        </Container>
      </ActionSection>
    </>
  )
}

export default AffiliateAction
