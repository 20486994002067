import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import shieldIcon from "../../images/affiliate-shield-icon.svg"
import briefcaseIcon from "../../images/affiliate-briefcase-icon.svg"
import handIcon from "../../images/affiliate-hand-icon.svg"
import { ActionH2 } from "./AffiliateStyle"
import styled from "styled-components"

function AffiliateTools({ propsData }) {
  const { Title, Point1, Point2, Point3, Desc1, Desc2, Desc3 } = propsData.Tools

  const dataRender = [
    {
      title: Point1,
      desc: Desc1,
      icon: shieldIcon,
    },
    {
      title: Point2,
      desc: Desc2,
      icon: briefcaseIcon,
    },
    {
      title: Point3,
      desc: Desc3,
      icon: handIcon,
    },
  ]

  return (
    <CustomContainer>
      <MainDiv>
        <TitleDiv>
          <ActionH2>{Title}</ActionH2>
        </TitleDiv>
        <Row>
          {dataRender?.map((item, index) => {
            const { title, desc, icon } = item || {}
            return (
              <Col lg={4} className="mb-4" key={index}>
                <CardDiv>
                  <CardTitle>{title}</CardTitle>
                  <CardDesc>{desc}</CardDesc>
                  <CardIcon src={icon} alt="icon" />
                </CardDiv>
              </Col>
            )
          })}
        </Row>
      </MainDiv>
    </CustomContainer>
  )
}

export default AffiliateTools

const CustomContainer = styled(Container)`
  margin-top: 100px;
  margin-bottom: 100px;
`

const MainDiv = styled.div`
  text-align: center;
`

const TitleDiv = styled.div`
  padding: 0px 100px;
  margin-bottom: 50px;
  @media (max-width: 576px) {
    padding: 0px 30px;
  }
`

const CardDiv = styled.div`
  background-color: #2ebcd0;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.07);
  border-radius: 15px;
  padding: 40px 40px;
  text-align: left;
  position: relative;
  height: 300px;
`

const CardTitle = styled.h5`
  font-family: "Typefez-black";
  color: #fff;
  font-size: 26px;
  height: 90px;
  @media (max-width: 992px){
    height: 70px;
  }
`

const CardDesc = styled.p`
  font-family: "Typefez-med";
  color: #fff;
  font-size: 16px;
`

const CardIcon = styled.img`
  position: absolute;
  bottom: 20px;
  right: 20px;
`
