import React, { useState, useEffect } from "react"
import axios from "axios"
import { navigate } from "@reach/router"
import { useForm } from "react-hook-form"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { Form, Col } from "react-bootstrap"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"

import {
  FormRow,
  FormLabel,
  ButtonDiv,
  ButtonSend,
  FlexFill,
  FormControl,
  DisclaimerDiv,
} from "./AffiliateStyle"

function FormAffiliate({ propsLink, propsData }) {
  const useFormObj = useForm()
  const [done, setDone] = useState(false)

  const {
    Label1,
    Label2,
    Label3,
    Label4,
    Label5,
    Label6,
    Label7,
    Placeholder1,
    Placeholder2,
    Placeholder3,
    Placeholder4,
    Placeholder5,
    Placeholder6,
    Placeholder7,
    Disclaimer,
    Disclaimer1,
    Button,
    Success,
  } = propsData

  const AffiliateFormData = [
    {
      name: "name",
      label: Label1,
      placeholder: Placeholder1,
      type: "text",
    },
    {
      name: "phone",
      label: Label7,
      placeholder: Placeholder7,
      type: "number",
    },
    {
      name: "email",
      label: Label2,
      placeholder: Placeholder2,
      type: "text",
    },
    {
      name: "instagram",
      label: Label3,
      placeholder: Placeholder3,
      type: "text",
    },
    {
      name: "facebook",
      label: Label4,
      placeholder: Placeholder4,
      type: "text",
    },
    {
      name: "website",
      label: Label5,
      placeholder: Placeholder5,
      type: "text",
    },
    {
      name: "youtube",
      label: Label6,
      placeholder: Placeholder6,
      type: "text",
    },
  ]

  const submit = async val => {
    const newVal = {
      ...val,
      phone: `${val.codePhone}${val.phone}`,
    }
    delete newVal["codePhone"]
    try {
      const headers = {
        Authorization:
          "eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo0fQ.HzngeSUFSrGEeCk4lL7zHElSUhljNf_0PSK1uB5UWkg",
      }
      await axios
        .post("https://api.webform.transfez.com/api/v1/affiliates", newVal, {
          headers: headers,
        })
        .then(res => {
          setDone(true)
          navigate(propsLink.AffiliateSubmitted)
        })
        .catch(err => {
          console.log(err)
        })
    } catch (error) {
      console.log(error)
    }
  }

  const { handleSubmit, watch, register, setValue } = useFormObj

  const checkerArray = array => {
    var typeArr = true
    for (let i = 0; i < array.length; i++) {
      if (array[i] === "") {
        typeArr = true
      } else {
        return (typeArr = false)
      }
    }
    return typeArr
  }

  const onTest = (value, e) => {
    setValue("codePhone", `(+${e.dialCode}) `)
    setValue("phone", value)
  }

  const reqField = ["name", "phone", "email"]
  const reqSocmed = ["instagram", "facebook", "website", "youtube"]
  const watchSocmed = watch(reqSocmed)
  const checkerSocmed = checkerArray(watchSocmed)

  return (
    <>
      <Form onSubmit={handleSubmit(submit)}>
        {AffiliateFormData.map(({ name, label, placeholder, type }, index) => {
          const isSup = reqField.includes(name)
          const isDoubleSup = reqSocmed.includes(name)
          const isPhone = name == "phone"
          return (
            <FormRow key={index}>
              <FormLabel className="col-4">
                {label}
                {isSup && <sup>*</sup>}
                {isDoubleSup && <sup>**</sup>}
              </FormLabel>
              <Col md={8}>
                {isPhone ? (
                  <PhoneInput
                    country={"id"}
                    value={watch("phone")}
                    onChange={onTest}
                    placeholder={placeholder}
                    countryCodeEditable={false}
                    inputStyle={{
                      fontFamily: "GothamBook",
                      fontSize: "16px",
                      width: "100%",
                      height: "38px",
                    }}
                  />
                ) : (
                  <FormControl
                    {...register(name, { required: isSup || checkerSocmed })}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                  />
                )}
              </Col>
            </FormRow>
          )
        })}
        <ButtonDiv>
          <DisclaimerDiv>
            <sup>*</sup>
            <small>{Disclaimer}</small>
            <br />
            <sup>**</sup>
            <small>{Disclaimer1}</small>
          </DisclaimerDiv>
          <ButtonSend type="submit">{Button}</ButtonSend>
        </ButtonDiv>
        <FlexFill>
          <small>
            {done && (
              <>
                <FontAwesomeIcon icon={faCheckCircle} /> {Success}
              </>
            )}
          </small>
        </FlexFill>
      </Form>
    </>
  )
}

export default FormAffiliate
