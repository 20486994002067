import { Link } from "gatsby"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"
import { OutlineButton } from "../Buttons/parent"
import { ActionButton } from "./AffiliateStyle"
import headerLeftImg from "../../images/affiliate-header-left.svg"
import headerRightImg from "../../images/affiliate-header-right.svg"

export const AffiliateHeader = ({ props }) => {
  const { Title, Desc, Button1, Button2 } = props || {}
  return (
    <Container style={{ marginTop: 80, position: "relative" }}>
      <MainDiv>
        <MainTitle>{Title}</MainTitle>
        <MainDesc>{Desc}</MainDesc>
        <CustomRow>
          <CustomCol md={6}>
            <Link to="#tnc">
              <ActionButton mbottom="10px" minWidth="300px">
                {Button1}
              </ActionButton>
            </Link>
          </CustomCol>
          <CustomCol md={6}>
            <Link to="#learn-more">
              <OutlineButton
                style={{ height: 40, borderRadius: 10, width: 200 }}
              >
                {Button2}
              </OutlineButton>
            </Link>
          </CustomCol>
        </CustomRow>
      </MainDiv>
      <ImgLeft src={headerLeftImg} alt="header" />
      <ImgRight src={headerRightImg} alt="header" />
    </Container>
  )
}

const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
`

const CustomRow = styled(Row)`
  margin-top: 50px;
  text-align: center;
  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;
  }
`
const CustomCol = styled(Col)`
  padding: 0px 15px;
`

const MainTitle = styled.h2`
  font-family: "Typefez-black";
  font-size: 72px;
  color: #2ebcd0;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 42px;
  }
`

const MainDesc = styled.h5`
  font-family: "Typefez-med";
  font-size: 20px;
  color: #102c57;
  text-align: center;
  @media (max-width: 576px) {
    font-size: 18px;
  }
`

const ImgLeft = styled.img`
  position: absolute;
  left: -200px;
  top: 30px;
  @media (max-width: 768px) {
    display: none;
  }
`
const ImgRight = styled.img`
  position: absolute;
  right: -350px;
  top: 30px;
  @media (max-width: 768px) {
    display: none;
  }
`
