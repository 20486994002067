import React from "react"
import { Link } from "gatsby"
import { useStateIfMounted } from "use-state-if-mounted"
import { Row } from "react-bootstrap"
import {
  AffiliateHeader,
  AffiliateContainer,
  AffiliateH1,
  AffiliateDot,
  AffiliateBorder,
  AffiliateCol,
  AffiliateP,
  AffiliateButtonDiv,
  AffiliateButtonYellow,
  AffiliateButtonTrans,
} from "./HeaderStyle"
import NavigationBar from "./NavigationBar"

import bgHeader from "../../images/bg-affiliate-header.jpg"
import bgHeaderRes from "../../images/bg-affiliate-header-mobile.jpg"

function HeaderAffiliate({ props, propsLink, propsData }) {
  const [statusNav, setStatusNav] = useStateIfMounted(false)
  const statusNavbar = status => {
    setStatusNav(status)
  }
  


  const { Title, Desc, Button1, Button2 } = propsData.Header
  return (
    <>
      <AffiliateHeader
        background={bgHeader}
        style={statusNav ? {paddingTop: "80px"}:null}
        bgres={bgHeaderRes}
      >
        <NavigationBar
          props={props}
          propsLink={propsLink}
          parentCallback={statusNavbar}
        />
        <AffiliateContainer>
          <div classname="salutation">
            <AffiliateH1>
              {Title}
              <AffiliateDot />
            </AffiliateH1>
            <AffiliateBorder />
            <Row className="mt-4">
              <AffiliateCol>
                <AffiliateP>{Desc}</AffiliateP>
                <AffiliateButtonDiv>
                  <Link to="#tnc">
                    <AffiliateButtonYellow>{Button1}</AffiliateButtonYellow>
                  </Link>
                  <Link to="#learn-more">
                    <AffiliateButtonTrans>{Button2}</AffiliateButtonTrans>
                  </Link>
                </AffiliateButtonDiv>
              </AffiliateCol>
            </Row>
          </div>
        </AffiliateContainer>
      </AffiliateHeader>
    </>
  )
}

export default HeaderAffiliate
