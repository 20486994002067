import React from "react"
import "../components/layout.css"
import { useLocation } from "@reach/router"
import { Container } from "react-bootstrap"
import { AffiliateHr } from "../components/Affiliate/AffiliateStyle"

import HeaderAffiliate from "../components/HeaderComponents/HeaderAffiliate"
import { AffiliateHeader } from "../components/Affiliate/AffiliateHeader"
import AffiliateBenefits from "../components/Affiliate/AffiliateBenefits"
import AffiliateHiw from "../components/Affiliate/AffiliateHiw"
import AffiliateTools from "../components/Affiliate/AffiliateTools"
import AffiliateAction from "../components/Affiliate/AffiliateAction"
import AffiliateFaq from "../components/Affiliate/AffiliateFaq"
import AffiliateTnc from "../components/Affiliate/AffiliateTnc"
import Footer from "../components/FooterComponents/Footer"

import { MainPageJson, PathJson, AffiliateJson } from "../components/location"
import Seo from "../components/SEOComponents/Seo"
import { NavigationBar } from "../components/NavigationBar/parent"

function Affiliate() {
  const MainPageContent = MainPageJson()
  const PathContent = PathJson()
  const AffiliateContent = AffiliateJson()
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")

  return (
    <div className="maxwidth">
      <Seo title="affiliate" />
      {/* <HeaderAffiliate
        props={MainPageContent.Header}
        propsLink={PathContent}
        propsData={AffiliateContent}
      /> */}
      <NavigationBar />
      <AffiliateHeader props={AffiliateContent.Header}/>
      <AffiliateBenefits propsData={AffiliateContent} />
      <AffiliateHiw propsData={AffiliateContent} />
      {/* <AffiliateTools propsData={AffiliateContent} /> */}
      {/* <AffiliateAction propsData={AffiliateContent} /> */}
      <AffiliateFaq propsData={AffiliateContent} />
      <AffiliateTnc propsData={AffiliateContent} propsLink={PathContent} />
      <Footer props={MainPageContent.Footer} propsLink={PathContent} />
    </div>
  )
}

export default Affiliate
