import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import imgBigCoin from "../../images/affiliate-big-coin.png"
import imgGraph from "../../images/affiliate-graph.png"
import imgRocket from "../../images/affiliate-rocket.png"
import { ActionH2 } from "./AffiliateStyle"
import styled from "styled-components"

function AffiliateBenefits({ propsData }) {
  const {
    Title,
    Point1,
    Point2,
    Point3,
    Desc1,
    Desc2,
    Desc3,
  } = propsData.Benefits

  const dataRendering = [
    {
      title: Point2,
      desc: Desc2,
      background: "#5A489C",
      icon: imgGraph,
    },
    {
      title: Point3,
      desc: Desc3,
      background: "#FF7B5A",
      icon: imgRocket,
    },
  ]
  return (
    <>
      <a
        id="learn-more"
        style={{ display: "block", paddingTop: "80px", marginTop: "-80px" }}
      ></a>
      <CustomContainer>
        <TitleDiv>
          <ActionH2>{Title}</ActionH2>
        </TitleDiv>
        <BoxDiv bcolor="#2EBCD0" margin992="0px">
          <Col lg={7}>
            <TextDiv>
              <MainTitle>{Point1}</MainTitle>
              <MainDesc>{Desc1}</MainDesc>
            </TextDiv>
          </Col>
          <Col lg={5} style={{ position: "relative" }}>
            <ImgBigGlobe src={imgBigCoin} alt="globe" />
          </Col>
        </BoxDiv>
        <Row>
          {dataRendering?.map((item, index) => {
            const { title, desc, background, icon } = item || {}
            const isEven = (index + 1) % 2 === 0
            const marginDecider = isEven
              ? "10px 65px 10px 10px"
              : "10px 10px 10px 65px"
            const isLast = dataRendering.length === index + 1
            return (
              <Col lg={6} key={index} style={{ padding: 0 }}>
                <BoxDiv
                  bcolor={background}
                  margin={marginDecider}
                  margin992="10px"
                >
                  <Col lg={7}>
                    <TextDiv width="100%">
                      <MainTitle fsize="32px">{title}</MainTitle>
                      <MainDesc>{desc}</MainDesc>
                    </TextDiv>
                  </Col>
                  <Col lg={5} style={{ position: "relative" }}>
                    <ImgCustom
                      src={icon}
                      alt={title}
                      bottom={isLast && "-40px"}
                      right={isLast && "-60px"}
                      bottom1200={isLast && "80px"}
                      right1200={isLast && "-60px"}
                      bottom992={isLast && "-70px"}
                      right992={isLast && "-60px"}
                      bottom768={isLast && "-80px"}
                      right768={isLast && "-60px"}
                    />
                  </Col>
                </BoxDiv>
              </Col>
            )
          })}
        </Row>
      </CustomContainer>
    </>
  )
}

export default AffiliateBenefits

const CustomContainer = styled(Container)`
  margin-top: 100px;
  margin-bottom: 150px;
`
const TitleDiv = styled.div`
  padding: 0px 100px;
  margin-bottom: 50px;
  text-align: center;
  @media (max-width: 576px) {
    padding: 0px 30px;
  }
`

const BoxDiv = styled(Row)`
  position: relative;
  overflow: hidden;
  padding: 30px 15px;
  height: ${p => p.height || "450px"};
  background-color: ${p => p.bcolor};
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  margin: ${p => p.margin || "10px 50px"};
  @media (max-width: 992px) {
    height: ${p => p.height992 || "450px"};
    margin: ${p => p.margin992};
  }
`

const TextDiv = styled.div`
  width: ${p => p.width || "70%"};
`

const MainTitle = styled.h4`
  font-size: ${p => p.fsize || "40px"};
  color: white;
  font-family: "Typefez-bold";
  margin-bottom: 50px;
  height: 70px;
  @media (max-width: 576px) {
    font-size: 32px;
  }
`

const MainDesc = styled.h5`
  font-size: 20px;
  color: white;
  font-family: "Typefez-med";
`

const ImgCustom = styled.img`
  position: absolute;
  bottom: ${p => p.bottom || "-30px"};
  right: ${p => p.right || "-10px"};
  @media (max-width: 1200px) {
    width: 220px;
    bottom: ${p => p.bottom1200 || "-30px"};
    right: ${p => p.right1200 || "-10px"};
  }
  @media (max-width: 992px) {
    width: 220px;
    bottom: ${p => p.bottom992 || "-30px"};
    right: ${p => p.right992 || "-10px"};
  }
  @media (max-width: 768px) {
    width: 170px;
    bottom: ${p => p.bottom768 || "-30px"};
    right: ${p => p.right768 || "-10px"};
  }
`

const ImgBigGlobe = styled.img`
  position: absolute;
  bottom: -90px;
  right: -95px;
  min-width: 500px;
  @media (max-width: 992px) {
    max-width: 350px !important;
    min-width: 300px !important;
    /* bottom: -40px; */
  }
  @media (max-width: 576px) {
    max-width: 250px !important;
    min-width: 200px !important;
  }
`
